import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { Ticket } from '@app/modules/shared/models/ticket';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { environment as env } from '@env/environment';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Stop } from '../models/od';

@Injectable()
export class TicketService {
  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {}

  public cancelTicket(ticket_id: number) {
    return firstValueFrom(
      this.http.post<any>(env.config.feedRoot + 'Ticket/cancelTicket.json', { ticket_id }).pipe(
        map(({ response: r }) => {
          return r.status === 'ok';
        })
      )
    );
  }

  public reactivateTicket(ticket_id: number) {
    const http$ = this.http
      .post<any>(env.config.feedRoot + 'Ticket/reactivateTicket', { ticket_id })
      .pipe(
        map(({ response }) => {
          return response.status === 'ok';
        })
      );

    return firstValueFrom(http$);
  }

  public generateTicketsV1(
    user_id: string,
    item_id: string,
    product_id: string,
    quantity: number,
    user_photo: string,
    dematerialized: string,
    od = null,
    start_validity_date = null,
    end_validity_date = null,
    generationReason = null,
    generationComment: string | null = null
  ) {
    return this.http
      .post<any>(env.config.feedRoot + 'Ticket/generateTicket.json', {
        user_id,
        item_id,
        product_id,
        quantity,
        user_photo,
        dematerialized,
        od,
        start_validity_date,
        end_validity_date,
        generationReason,
        generationComment,
      })
      .pipe(
        map(({ response: r }) => {
          return (r?.tickets as Ticket[]) || [];
        })
      );
  }

  public generateTicketsV2(
    generationReason: string,
    blueprintId: string,
    customerId: string,
    networkId: string,
    startDate = null,
    endDate = null,
    generationComment: string | null = null
  ) {
    return this.http
      .post<any>(env.config.feedRoot + 'Wallet/createContract.json', {
        generationReason,
        blueprintId,
        customerId,
        networkId,
        startDate,
        endDate,
        generationComment,
      })
      .pipe(
        map(({ response: r }) => {
          return r?.contract || null;
        })
      );
  }

  public getValidations(): Observable<any> {
    return this.http.get<any>(env.config.feedRoot + 'TicketValidation/getValidation.json').pipe(
      map(({ response: r }) => {
        return r.validation;
      }),
      catchError(() => throwError('Erreur serveur !'))
    );
  }

  public getODStops(productId: string, originStop: string = null): Observable<Stop[]> {
    const params: {
      product_id: string;
      origin_stop?: string;
    } = {
      product_id: productId,
    };

    if (originStop) {
      params.origin_stop = originStop;
    }

    return this.http
      .get<GenericResponse<{ stops: Stop[] }>>(env.config.feedRoot + 'Transit/getODStops.json', {
        params,
      })
      .pipe(
        map(({ response }) => {
          return response.stops;
        })
      );
  }

  public getODTrips(productId: string, originStop: string, destinationStop: string) {
    return this.http
      .get<any>(env.config.feedRoot + `Transit/ODTrips.json`, {
        params: {
          productId,
          originStop,
          destinationStop,
        },
      })
      .pipe(
        map(({ response }) => {
          return response.trips;
        })
      );
  }
}
