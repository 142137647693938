<ng-container *ngIf="!$$loadingCustomer; else loadingUser">
  <!-- GENERAL INFORMATION -->
  <tu-card [withHeader]="true" [status]="!isCustomerActive ? 'error' : 'default'">
    <div
      card-header
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-between md:tw-flex-row"
    >
      <!-- Anonymisation info + firstname / lastname -->
      <p
        class="tw-flex tw-flex-col tw-items-center tw-space-x-0 tw-font-bold tw-leading-none md:tw-mb-0 md:tw-flex-1 md:tw-flex-row md:tw-space-x-1.5"
        [ngClass]="!isCustomerActive ? 'tw-text-white' : 'tw-text-gray-800'"
      >
        <span class="tw-text-lg">
          {{ $$customer.customer_id }}
        </span>

        <span>&ndash;</span>

        <span *ngIf="$$customer.anonymized_at; else notAnonymised">
          {{ 'pages.customer_details.anonymize_date' | translate }}
          {{ $$customer.anonymized_at | date : 'shortDate' : undefined : translate.currentLang }}
        </span>

        <ng-template #notAnonymised>
          <span class="tw-text-lg">{{ $$customer.firstname }} {{ $$customer.lastname }}</span>
          <span *ngIf="$$customer.authLastAsGuest" class="tag tag-pill tag-primary tw-p-2">
            {{ 'pages.customer.guest_mode' | translate }}
          </span>
        </ng-template>
      </p>

      <!-- Actions dropdown -->
      <div class="tw-flex tw-w-full tw-justify-end md:tw-relative md:tw-w-auto">
        <button
          (click)="this.isActionOpen = true"
          type="button"
          [ngClass]="[
            isCustomerActive
              ? 'tw-text-gray-500 hover:tw-text-gray-700 focus:tw-text-gray-700'
              : 'tw-text-gray-200 hover:tw-text-gray-100 focus:tw-text-gray-100'
          ]"
          class="tw-flex tw-rounded-md tw-p-2 focus:tw-outline-none"
        >
          <span>{{ 'pages.customer_details.action' | translate }}</span>

          <tu-hero-icon
            [path]="heroicons.solid.chevronDown"
            klass="tw-h-5 tw-inline-block tw-mx-auto"
          ></tu-hero-icon>
        </button>

        <!--Click away trigger-->
        <div
          *ngIf="isActionOpen"
          (click)="isActionOpen = false"
          class="tw-fixed tw-inset-0 tw-z-20 tw-h-screen tw-w-screen"
        ></div>

        <ul
          *ngIf="isActionOpen"
          class="tw-absolute tw-right-0 tw-z-30 tw-mt-10 tw-flex tw-flex-col tw-rounded-md tw-bg-white tw-py-2 tw-shadow-xl"
        >
          <!-- Disable custonmer button -->
          <li>
            <button
              (click)="toggleCustomerStatus()"
              type="button"
              [hasAllPermission]="['TOGGLE_CUSTOMER_ACCOUNT']"
              [disabled]="$$customer.anonymized_at || !operatorHasSameNetworks"
              [ngClass]="[
                isCustomerActive
                  ? 'tw-text-red-700 hover:tw-text-red-800'
                  : 'tw-text-gray-600 hover:tw-text-gray-700'
              ]"
              class="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm disabled:tw-text-gray-400"
            >
              <tu-hero-icon
                [path]="heroicons.outline.ban"
                klass="tw-h-5 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span>
                <tu-tooltip *ngIf="!operatorHasSameNetworks" class="tw-mr-2" [config]="{ width: 'tw-w-32', classList: 'tw-whitespace-normal' }">
                  {{ 'pages.customer_details.contact_support' | translate }}
                </tu-tooltip>
                {{
                  (isCustomerActive
                    ? 'pages.customer_details.turn_off_account'
                    : 'pages.customer_details.turn_on_account'
                  ) | translate
                }}
              </span>
            </button>
          </li>

          <!-- Anonymisation button -->
          <li>
            <button
              (click)="openAnonymisationModal()"
              [hasAllPermission]="['TOGGLE_CUSTOMER_ACCOUNT']"
              type="button"
              [disabled]="isCustomerActiveOrAnonymized"
              [title]="
                isCustomerActive
                  ? ('pages.customer_details.anonymize_tooltip_message' | translate)
                  : ''
              "
              class="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 hover:tw-text-gray-700 disabled:tw-text-gray-400"
            >
              <tu-hero-icon
                [path]="heroicons.outline.eyeOff"
                klass="tw-h-5 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span>{{ 'pages.customer_details.anonymize' | translate }}</span>
            </button>
          </li>

          <!-- Send verification mail button -->
          <li>
            <button
              (click)="sendVerificationMail()"
              [hasAllPermission]="['TOGGLE_CUSTOMER_ACCOUNT']"
              type="button"
              [disabled]="$$customer.verified_at || isVerificationMailsent || !$$customer.email"
              class="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 hover:tw-text-gray-700 disabled:tw-text-gray-400"
            >
              <tu-hero-icon
                [path]="heroicons.outline.shieldExclamation"
                klass="tw-h-5 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span>{{ 'pages.customer_details.send_verification_mail' | translate }}</span>
            </button>
          </li>

          <!-- Manual validation  -->
          <li>
            <button
              (click)="accountCheck()"
              [hasAllPermission]="['TOGGLE_CUSTOMER_ACCOUNT']"
              type="button"
              [disabled]="!isCheckAllowed"
              [title]="translate.instant('pages.customer_details.account_check_validation')"
              class="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 hover:tw-text-gray-700 disabled:tw-text-gray-400"
            >
              <tu-hero-icon
                [path]="heroicons.outline.badgeCheck"
                klass="tw-h-5 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span>{{ 'pages.customer_details.account_check_validation' | translate }}</span>
            </button>
          </li>

          <!-- Reset password button -->
          <li>
            <button
              (click)="sendResetPasswordEmail()"
              [hasAllPermission]="['MANAGE_USERS']"
              [disabled]="!canResetPassword"
              class="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 hover:tw-text-gray-700 disabled:tw-text-gray-400"
            >
              <tu-hero-icon
                [path]="heroicons.outline.sparkles"
                klass="tw-h-5 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span>{{ 'pages.customer_details.reset_password' | translate }}</span>
            </button>
          </li>

          <!-- Update information button -->
          <li>
            <button
              (click)="openInformationModal()"
              [hasAllPermission]="['MANAGE_CUSTOMER_INFO']"
              class="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 hover:tw-text-gray-700"
            >
              <tu-hero-icon
                [path]="heroicons.outline.user"
                klass="tw-h-5 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span>{{ 'pages.customer_details.update_personal_information' | translate }}</span>
            </button>
          </li>

          <!-- Lost device -->
          <li *ngIf="hasV2Tickets">
            <button
              (click)="openLostPhoneModal()"
              type="button"
              [title]="'pages.customer_details.disconnect_sessions' | translate"
              class="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 hover:tw-text-gray-700 disabled:tw-text-gray-400"
            >
              <tu-hero-icon
                [path]="heroicons.outline.lockOpen"
                klass="tw-h-5 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span>{{ 'pages.customer_details.phone_lost' | translate }}</span>
            </button>
          </li>

          <!-- Fusion -->
          <li>
            <button
              [disabled]="isFusionDisabled"
              (click)="openFusionModal()"
              type="button"
              class="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 hover:tw-text-gray-700 disabled:tw-text-gray-400"
            >
              <tu-hero-icon
                [path]="heroicons.outline.users"
                klass="tw-h-5 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span>{{ 'pages.customer_details.user_fusion' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div card-content class="tw-relative tw-flex tw-flex-col md:tw-flex-row">
      <div class="tw-flex tw-flex-col tw-items-center md:tw-mr-4">
        <div class="tw-relative">
          <a [href]="$$customer.picture" target="_blank" rel="noopener">
            <img
              class="tw-m-2 tw-h-24 tw-w-24 tw-rounded-2xl tw-object-cover"
              [default]="
                '/assets/img/user' + ($$customer.authLastAsGuest ? '-anonymous' : '') + '.svg'
              "
              [alt]="'pages.customer_details.user_picture' | translate"
              [src]="$$customer.picture"
            />
          </a>

          <!-- Update profile picture button -->
          <button
            type="button"
            class="tw-absolute tw-right-0 tw-bottom-0 tw-ml-3 tw-rounded-full tw-bg-green-400 tw-p-1 tw-text-xs tw-font-medium tw-tracking-wider tw-text-white tw-transition tw-duration-300 tw-ease-in hover:tw-bg-green-500"
          >
            <span class="tw-sr-only">
              {{ 'pages.customer_details.update_user_picture' | translate }}
            </span>

            <tu-hero-icon
              (click)="openUserPhotoModal()"
              [hasAllPermission]="['MANAGE_CUSTOMER_INFO']"
              [path]="heroicons.outline.pencil"
              klass="tw-h-5 tw-text-white tw-inline-block tw-mx-auto"
            ></tu-hero-icon>
          </button>
        </div>
      </div>

      <div
        class="tw-my-4 tw-flex tw-flex-col tw-items-center tw-text-gray-500 md:tw-my-0 md:tw-items-baseline"
      >
        <div *ngIf="$$customer.email" class="tw-flex tw-gap-2">
          <tu-hero-icon
            [path]="heroicons.outline.mail"
            klass="tw-h-5 tw-text-gray-400 tw-inline-block tw-mx-auto"
          ></tu-hero-icon>

          <a
            class="hover:tw-no-underline"
            href="mailto:{{ $$customer.email }}"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $$customer.email }}
          </a>

          <div
            class="tw-text-white tw-px-2 tw-rounded-xl"
            [class]="{
              'tw-bg-green-500': emailIsValid,
              'tw-bg-red-500': !emailIsValid
            }"
            [ngbTooltip]="!emailIsValid && $$customer.emailBouncedReason ? (('pages.customer_details.' + $$customer.emailBouncedReason | lowercase) | translate) : undefined"
          >
            <span class="tw-font-semibold tw-text-xs">{{ ( emailIsValid ? 'pages.customer_details.valid' : 'pages.customer_details.invalid') | translate }}</span>
          </div>
        </div>

        <div *ngIf="$$customer.address" class="tw-flex tw-space-x-3">
          <tu-hero-icon
            [path]="heroicons.outline.officeBuilding"
            klass="tw-h-5 tw-text-gray-400 tw-inline-block tw-mx-auto"
          ></tu-hero-icon>

          <p>
            <span>{{ $$customer.address.streetNumber }} {{ $$customer.address.route }}</span>
            <br *ngIf="$$customer.address.zipCode || $$customer.address.city" />

            <span>{{ $$customer.address.zipCode }} {{ $$customer.address.city }}</span>
            <br *ngIf="$$customer.address.country" />

            <span>{{ $$customer.address.country }}</span>
          </p>
        </div>

        <p *ngIf="$$customer.phone">
          <tu-hero-icon
            [path]="heroicons.outline.phone"
            klass="tw-h-5 tw-text-gray-400 tw-inline-block tw-mx-auto"
          ></tu-hero-icon>

          <span>{{ $$customer.phone }}</span>
        </p>

        <p *ngIf="$$customer.birthday">
          <tu-hero-icon
            [path]="heroicons.outline.cake"
            klass="tw-h-5 tw-text-gray-400 tw-inline-block tw-mx-auto"
          ></tu-hero-icon>

          <span>
            {{ $$customer.birthday | date : 'shortDate' : undefined : translate.currentLang }}
          </span>
        </p>

        <div class="tw-mb-3 tw-flex tw-flex-col tw-items-center md:tw-items-start">
          <p class="tw-mb-0">
            <span class="tw-text-gray-400">
              {{ 'pages.customer_details.signin' | translate }} :
            </span>

            <strong>
              {{ $$customer.date | date : 'short' : undefined : translate.currentLang }}
            </strong>
          </p>

          <p *ngIf="isCustomerParent" class="tw-mb-0">
            <span class="tw-text-gray-400">
              {{ 'pages.customer_details.last_activity_label' | translate }} :
            </span>

            <strong>
              {{
                $$customer.lastActivityAt
                  ? ($$customer.lastActivityAt
                    | date : 'shortDate' : undefined : translate.currentLang)
                  : ('pages.customer_details.unknown' | translate)
              }}
            </strong>
          </p>
        </div>

        <p *ngIf="$$customer.locale">
          <span class="tw-text-gray-400">
            {{ 'pages.customer_details.network_language' | translate }}
          </span>

          <strong>
            {{ 'otherslabels.' + $$customer.locale | lowercase | translate }}
          </strong>
        </p>

        <div *ngIf="$$customer.anonymization_requested_at" class="tw-flex tw-flex-col">
          <p class="mb-0">{{ 'pages.customer_details.account_deletion' | translate }} :</p>

          <p class="mb-0">
            <span class="tw-text-gray-400">
              {{ 'pages.customer_details.account_deletion_request_date' | translate }} :
            </span>

            <strong>
              {{
                $$customer.anonymization_requested_at
                  | date : 'shortDate' : undefined : translate.currentLang
              }}
            </strong>
          </p>

          <p>
            <span class="tw-text-gray-400">
              {{ 'pages.customer_details.account_deletion_notification_date' | translate }} :
            </span>

            <strong *ngIf="$$customer.anonymization_notified_at; else notificationNotSent">
              {{
                $$customer.anonymization_notified_at
                  | date : 'shortDate' : undefined : translate.currentLang
              }}
            </strong>

            <ng-template #notificationNotSent>
              <strong>
                {{ 'pages.customer_details.notification_not_sent' | translate }}
              </strong>
            </ng-template>
          </p>
        </div>

        <p>
          <span
            class="tag tag-pill tw-p-2"
            [ngClass]="$$customer.verified_at ? 'tag-success' : 'tag-danger'"
          >
            {{
              (!$$customer.verified_at
                ? 'pages.customer_details.verified_not'
                : 'pages.customer_details.verified'
              ) | translate
            }}
          </span>
        </p>
      </div>
    </div>
  </tu-card>

  <!-- PROFILES -->
  <tu-card klass="tw-mt-6">
    <div card-content class="table-responsive profiles">
      <h5 class="card-title">{{ 'pages.customer_details.profiles' | translate }}</h5>

      <ng-container *ngIf="!$$loadingProfiles; else loadingProfiles">
        <table
          *ngIf="$$profiles.length; else noProfiles"
          class="table table-align-middle table-hover"
        >
          <caption class="tw-sr-only">
            {{ 'pages.customer_details.profiles_list' | translate }}
          </caption>
          <thead>
            <tr>
              <th></th>
              <th>{{ 'pages.customer_details.firstname' | translate }}</th>
              <th>{{ 'pages.customer_details.lastname' | translate }}</th>
              <th>{{ 'pages.customer_details.birthday' | translate }}</th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let profile of $$profiles"
              (click)="changeProfile(profile.customer_id)"
              [ngClass]="{
                'table-active': profile.customer_id === $$customer?.customer_id,
                disabled: !profile.active
              }"
            >
              <td class="avatar_cell">
                <a
                  [routerLink]="['/customers', profile.customer_id]"
                  class="tw-flex tw-items-center tw-justify-center"
                >
                  <div class="tw-relative">
                    <i
                      class="fa fa-star fa-lg tw-absolute tw-top-0 tw-right-0 tw-z-10"
                      *ngIf="profile.isParent"
                    ></i>
                    <img
                      [src]="profile.picture"
                      [default]="
                        '/assets/img/user' +
                        ($$customer.authLastAsGuest ? '-anonymous' : '') +
                        '.svg'
                      "
                      [alt]="'pages.customer_details.user_picture' | translate"
                      class="img-avatar avatar"
                    />
                  </div>
                </a>
              </td>
              <td>
                <a [routerLink]="['/customers', profile.customer_id]" class="items-center tw-flex">
                  {{ profile.firstname }}
                </a>
              </td>
              <td>
                <a [routerLink]="['/customers', profile.customer_id]" class="items-center tw-flex">
                  {{ profile.lastname }}
                </a>
              </td>
              <td>
                <a [routerLink]="['/customers', profile.customer_id]" class="items-center tw-flex">
                  {{ profile.birthdate | date: 'shortDate' }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <ng-template #noProfiles>
          <p>{{ 'pages.customer_details.no_children_profil' | translate }}</p>
        </ng-template>
      </ng-container>

      <ng-template loadingProfiles>
        <p>{{ 'pages.customer_details.profiles_load' | translate }}</p>
      </ng-template>
    </div>
  </tu-card>

  <!-- STATS -->
  <div class="tw-mt-6">
    <div class="row">
      <div class="col-md-2">
        <div class="card no-min-height">
          <div class="clearfix p-1 card-block">
            <i class="p-1 mr-1 icon-wallet bg-info font-2xl pull-left"></i>
            <div class="mb-0 h5 text-info mt-h">{{ statusType['REMAINING'].count }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">
              {{ statusType['REMAINING'].label | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="card no-min-height">
          <div class="clearfix p-1 card-block">
            <i class="p-1 mr-1 icon-clock bg-warning font-2xl pull-left"></i>
            <div class="mb-0 h5 text-warning mt-h">{{ statusType['ACTIVE'].count }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">
              {{ statusType['ACTIVE'].label | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="card no-min-height">
          <div class="clearfix p-1 card-block">
            <i class="p-1 mr-1 fa fa-bus bg-success font-2xl pull-left"></i>
            <div class="mb-0 h5 text-success mt-h">{{ statusType['PENDING'].count }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">
              {{ statusType['PENDING'].label | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="card no-min-height">
          <div class="clearfix p-1 card-block">
            <i class="p-1 mr-1 fa fa-hourglass-end bg-danger font-2xl pull-left"></i>
            <div class="mb-0 h5 text-danger mt-h">{{ statusType['EXPIRED'].count }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">
              {{ statusType['EXPIRED'].label | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="card no-min-height">
          <div class="clearfix p-1 card-block">
            <i class="p-1 mr-1 fa fa-exchange bg-info font-2xl pull-left"></i>
            <div class="mb-0 h5 text-info mt-h">{{ statusType['TRANSFER_OUT'].count }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">
              {{ statusType['TRANSFER_OUT'].label | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="card no-min-height">
          <div class="clearfix p-1 card-block">
            <i class="p-1 mr-1 fa fa-gift bg-info font-2xl pull-left"></i>
            <div class="mb-0 h5 text-info mt-h">{{ statusType['TRANSFER_IN'].count }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">
              {{ statusType['TRANSFER_IN'].label | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FILTERS -->
  <tu-card [withHeader]="true">
    <div card-header>
      {{ 'pages.order.filters' | translate }}
    </div>

    <form
      card-content
      [formGroup]="filterForm"
      (ngSubmit)="
        $$filteredWallet = filterWalletContent($$wallet);
        $$filteredContracts = filterWalletContent($$contracts);
        computeBookingFilters();
      "
      class="tw-flex tw-w-full tw-flex-col"
    >
      <fieldset class="tw-flex tw-flex-col md:tw-flex-row">
        <legend class="tw-sr-only">
          {{ 'pages.customer_details.wallet_filters' | translate }}
        </legend>

        <div class="input-group md:tw-px-2">
          <span class="input-group-addon" id="table-search">
            <i class="fa fa-search"></i>
          </span>

          <input
            formControlName="customerQuery"
            type="search"
            class="form-control avatar-find"
            placeholder="{{ 'pages.order.search' | translate }}"
            aria-describedby="table-search"
          />
        </div>

        <div
          *ngIf="auth.networks.length > 1"
          class="input-group tw-mt-4 tw-flex md:tw-mt-0 md:tw-px-2"
        >
          <span class="input-group-addon" id="table-search">
            <i class="fa fa-map-signs"></i>
          </span>

          <input
            type="search"
            #networkInput
            formControlName="customerNetworkName"
            [matAutocomplete]="auto"
            class="form-control"
            placeholder="{{ 'pages.order.all_networks' | translate }}"
          />

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let name of filteredNetworksName | async"
              [ngClass]="{
                selected:
                  filterForm.controls.customerNetworkName.value?.toLowerCase() ===
                  name.toLowerCase()
              }"
              [value]="name"
            >
              <div class="tw-inline-block">{{ name }}</div>
            </mat-option>
          </mat-autocomplete>

          <div class="input-group-append">
            <button
              class="btn btn-danger"
              type="button"
              [disabled]="filterForm.controls.customerNetworkName.value === null"
              (click)="resetNetworkFilter()"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>

        <div class="input-group tw-mt-4 md:tw-mt-0 md:tw-px-2">
          <div class="input-group-addon">
            <i class="fa fa-bookmark"></i>
            <label class="sr-only" for="ticket_status">
              {{ 'pages.customer_details.title_status' | translate }}
            </label>
          </div>

          <select class="form-control" id="ticket_status" formControlName="status">
            <option class="selector-title" disabled="disabled" [ngValue]="null">
              {{ 'pages.customer_details.title_status' | translate }}
            </option>

            <option class="selector-title" [ngValue]="null">
              {{ 'pages.customer_details.all' | translate }}
            </option>

            <option class="selector-title" value="ACTIVE">
              {{ 'pages.customer_details.to_be_validated' | translate }}
            </option>

            <option class="selector-title" value="PENDING">
              {{ 'pages.customer_details.validated' | translate }}
            </option>

            <option class="selector-title" value="REMAINING">
              {{ 'pages.customer_details.remaining' | translate }}
            </option>

            <option class="selector-title" value="EXPIRED">
              {{ 'pages.customer_details.expired' | translate }}
            </option>
          </select>
        </div>

        <div class="input-group tw-mt-4 md:tw-mt-0 md:tw-px-2">
          <div class="input-group-addon">
            <i class="fa fa-user"></i>
            <label class="sr-only" for="origin">
              {{ 'pages.customer_details.sale' | translate }}
            </label>
          </div>

          <select class="form-control" id="origin" formControlName="origin">
            <option class="selector-title" [ngValue]="null">
              {{ 'pages.customer_details.all' | translate }}
            </option>
            <option class="selector-title" value="OPERATOR">
              {{ 'pages.customer_details.origin_operator' | translate }}
            </option>
            <option class="selector-title" value="SHOP">
              {{ 'pages.customer_details.origin_shop' | translate }}
            </option>
          </select>
        </div>
      </fieldset>

      <div class="tw-mt-4 tw-ml-auto tw-flex tw-items-center tw-space-x-2">
        <button type="reset" class="btn btn-outline-warning" (click)="resetForm()">
          {{ 'pages.order.reset_form' | translate }}
        </button>

        <button type="submit" class="btn btn-primary">
          {{ 'pages.order.apply_filters' | translate }}
        </button>
      </div>
    </form>
  </tu-card>

  <!-- WALLET -->
  <tu-closable-card klass="tw-mt-6" [defaultOpen]="true">
    <div
      card-header
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-between md:tw-flex-row"
    >
      <h5 class="tw-m-0 md:tw-flex-1">
        {{ 'pages.customer_details.user_wallet' | translate }}
      </h5>

      <button
        [hasAllPermission]="['MANAGE_CUSTOMER_WALLET']"
        class="btn btn-secondary btn-sm tw-mt-4 tw-ml-4 md:tw-mt-0"
        (click)="openTicketGenerationModal()"
        [disabled]="disabledAddTicket"
      >
        {{ 'pages.customer_details.add_title' | translate }}
      </button>

      <button
        [hasAllPermission]="['MANAGE_CUSTOMER_WALLET']"
        class="btn btn-danger btn-sm tw-mt-4 tw-ml-4 md:tw-mt-0"
        [ngClass]="{ disabled: !areTicketsSelected }"
        (click)="cancelTickets()"
        [disabled]="activeTicketsTab === 'booking'"
        placement="left"
        [ngbTooltip]="
          areTicketsSelected
            ? ''
            : ('pages.customer_details.delete_tickets_tooltip_message' | translate)
        "
      >
        {{ 'pages.customer_details.delete_tickets' | translate }}
      </button>
    </div>

    <!-- TODO: Handle this -->
    <ng-container card-content class="tw-mt-6">
      <tu-tabs>
        <tu-tab
          #ticketV1Tab
          [tabTitle]="'pages.customer_details.v1_tickets' | translate"
          [active]="isTicketV1Enabled"
          *ngIf="isTicketV1Enabled"
        >
          <tu-card [withHeader]="isTicketV2Enabled" status="info" icon="fa fa-exclamation-triangle">
            <p card-header class="tw-m-0 tw-text-base tw-font-semibold">
              {{ 'pages.customer_details.sav_ticketv2' | translate }}
            </p>
            <div card-content>
              <div *ngIf="$$errorWallet" class="tw-bg-red-100 tw-text-red-900 tw-border tw-border-solid tw-border-red-300 tw-p-4">
                {{ 'otherslabels.generic_error' | translate }}
              </div>

              <tu-table
                *ngIf="!$$loadingWallet && !$$errorWallet"
                id="wallet"
                [config]="walletTableConfig"
                [content]="$$filteredWallet"
                [checkboxes]="true"
                [simpleCheckboxes]="true"
                (checkedRows)="$$selectedTicketRows = $event"
                class="tw-mt-8"
              ></tu-table>
            </div>
          </tu-card>
        </tu-tab>

        <tu-tab
          #ticketV2Tab
          [tabTitle]="'pages.customer_details.v2_tickets' | translate"
          [active]="!isTicketV1Enabled && isTicketV2Enabled"
          *ngIf="isTicketV2Enabled"
        >
          <tu-card>
            <div card-content>
              <div *ngIf="$$errorContracts" class="tw-bg-red-100 tw-text-red-900 tw-border tw-border-solid tw-border-red-300 tw-p-4">
                {{ 'otherslabels.generic_error' | translate }}
              </div>
              <tu-table
                *ngIf="!$$loadingContracts && !$$errorContracts"
                id="wallet-v2"
                [config]="contractTableConfig"
                [content]="$$filteredContracts"
                [checkboxes]="true"
                [simpleCheckboxes]="true"
                (checkedRows)="$$selectedTicketRows = $event"
                class="tw-mt-8"
              ></tu-table>
            </div>
          </tu-card>
        </tu-tab>

        <tu-tab
          #bookingTab
          [tabTitle]="'pages.customer_details.order_booking' | translate"
          [active]="!isTicketV1Enabled && !isTicketV2Enabled && isBookingEnabled"
          *ngIf="isBookingEnabled"
        >
        <tu-card>
          <tu-table-server
            card-content
            [hasCheckbox]="false"
            [serverConfig]="serverPaginationService.serverConfig('customer/belongings', 'belongings')"
            [columnsConfig]="bookingTableConfig"
            [fetchConfig]="serverPaginationService.fetchConfig"
            [filters]="bookingFilters"
            [setQueryParams]="false"
            class="tw-mt-8"
          ></tu-table-server>
        </tu-card>
        </tu-tab>
      </tu-tabs>
    </ng-container>
  </tu-closable-card>

  <!-- VALIDATIONS -->
  <tu-closable-card [defaultOpen]="true" klass="tw-mt-6" *ngIf="customerValidationsView !== 'NONE'">
    <h5 card-header class="tw-m-0">
      {{ 'pages.validation.validations' | translate }}
      <tu-tooltip>
        {{ 'pages.customer_details.validations_last_48h' | translate }}
      </tu-tooltip>
    </h5>
    <div card-content [ngSwitch]="customerValidationsView">
      <ng-template *ngSwitchCase="'V1'" [ngTemplateOutlet]="customerValidationsTable"></ng-template>
      <ng-template
        *ngSwitchCase="'V2'"
        [ngTemplateOutlet]="customerWalletEventsTable"
      ></ng-template>
      <tu-tabs *ngSwitchCase="'TABS'">
        <tu-tab
          #customerValidationsTab
          [tabTitle]="'pages.customer_details.v1_validations' | translate"
        >
          <tu-card>
            <ng-template card-content [ngTemplateOutlet]="customerValidationsTable"></ng-template>
          </tu-card>
        </tu-tab>
        <tu-tab
          #customerWalletEventsTab
          [tabTitle]="'pages.customer_details.v2_validations' | translate"
        >
          <tu-card>
            <ng-template card-content [ngTemplateOutlet]="customerWalletEventsTable"></ng-template>
          </tu-card>
        </tu-tab>
      </tu-tabs>
    </div>
  </tu-closable-card>

  <ng-template #customerValidationsTable>
    <tu-table
      *ngIf="!loadingValidations"
      id="customerValidations"
      [config]="validationsTableConfig"
      [content]="$$validations"
      [checkboxes]="false"
    ></tu-table>
  </ng-template>
  <ng-template #customerWalletEventsTable>
    <tu-table
      *ngIf="!loadingWalletEvents && !$$loadingContracts"
      id="customerWalletEvents"
      [config]="walletEventsTableConfig"
      [content]="$$walletEvents"
      [checkboxes]="false"
    ></tu-table>
  </ng-template>

  <!-- ORDERS HISTORY  -->
  <tu-closable-card klass="tw-mt-6" [defaultOpen]="true" *ngIf="showCustomerOrders">
    <div
      card-header
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-between md:tw-flex-row"
    >
      <h5 class="tw-m-0 md:tw-flex-1">{{ 'pages.customer_details.order_history' | translate }}</h5>
    </div>
    <div card-content>
      <p class="tw-text-xl">{{ 'pages.order.filters' | translate }}</p>

      <form
        [formGroup]="filterOrderHistory"
        (ngSubmit)="computeOrderHistoryFilter()"
        class="tw-flex tw-w-full tw-flex-col tw-gap-2"
      >
        <!-- fields -->
        <fieldset class="tw-grid tw-grid-cols-3">
          <div class="input-group tw-flex">
            <div
              class="input-group-addon tw-flex tw-w-auto !tw-min-w-min tw-items-center tw-justify-center"
            >
              <i class="fa fa-ticket fa-lg"></i>
              <label class="sr-only" for="status">
                {{ 'pages.order.sale_status' | translate }}
              </label>
            </div>

            <select class="form-control tw-order-1 tw-h-auto" id="status" formControlName="status">
              <option class="selector-title" disabled="disabled" [ngValue]="null">
                {{ 'pages.order.sale_status' | translate }}
              </option>
              <option class="selector-title" [ngValue]="null">
                {{ 'pages.order.all' | translate }}
              </option>
              <option class="selector-title" value="COMPLETED">
                {{ 'pages.order.ok' | translate }}
              </option>
              <option class="selector-title" value="ERROR">
                {{ 'pages.order.refused' | translate }}
              </option>
              <option class="selector-title" value="PENDING">
                {{ 'pages.order.unfinished' | translate }}
              </option>
              <option class="selector-title" value="CANCELED">
                {{ 'pages.order.cancelled' | translate }}
              </option>
              <option class="selector-title" value="REGULARIZATION">
                {{ 'pages.order.regularization' | translate }}
              </option>
              <option class="selector-title" value="TO_REFUND">
                {{ 'pages.order.to_refund' | translate }}
              </option>
              <option class="selector-title" value="REFUNDED">
                {{ 'pages.order.refunded' | translate }}
              </option>
              <option class="selector-title" value="PAYMENT_ISSUE">
                {{ 'pages.order.default_payment' | translate }}
              </option>
            </select>
          </div>
        </fieldset>

        <!-- Action button -->
        <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
          <button
            type="reset"
            class="btn btn-outline-warning"
            (click)="filterOrderHistory.reset(); computeOrderHistoryFilter()"
          >
            {{ 'pages.order.reset_form' | translate }}
          </button>
          <button type="submit" class="btn btn-primary">
            {{ 'pages.order.apply_filters' | translate }}
          </button>
        </div>
      </form>

      <div class="tw-mt-8">
        <tu-table
          id="customerOrders"
          [config]="customerOrdersTableConfig"
          [content]="customerOrders"
          [checkboxes]="false"
        ></tu-table>
      </div>
    </div>
  </tu-closable-card>

  <!-- MEDIAS -->
  <tu-closable-card klass="tw-mt-6">
    <h5 card-header class="tw-m-0">{{ 'pages.customer_details.medias' | translate }}</h5>

    <div card-content>
      <tu-table
        id="medias"
        [config]="mediaTableConfig"
        [content]="$$medias"
        [checkboxes]="false"
      ></tu-table>
    </div>
  </tu-closable-card>

  <!-- SUBSCRIPTIONS -->
  <tu-closable-card [defaultOpen]="true" klass="tw-mt-6">
    <h5 card-header class="tw-m-0">{{ 'pages.subscriptions.subscriptions' | translate }}</h5>

    <div card-content>
      <tu-table
        *ngIf="$$products.length"
        id="subscriptions"
        [config]="subscriptionTableConfig"
        [content]="$$subscriptions"
        [checkboxes]="false"
        (rowClicked)="router.navigate(['subscriptions', $event.id])"
      ></tu-table>
    </div>
  </tu-closable-card>

  <!-- TRANSFERS HISTORY -->
  <tu-closable-card [defaultOpen]="true" klass="tw-mt-6" *ngIf="customer_history.length">
    <h5 card-header class="tw-m-0">{{ 'pages.customer_details.transfered_titles' | translate }}</h5>
    <div card-content>
      <tu-table
        id="transferHistory"
        [config]="transfersHistoryTableConfig"
        [content]="customer_history"
        [checkboxes]="false"
      ></tu-table>
    </div>
  </tu-closable-card>

  <!-- ASSOCIATED NETWORKS -->
  <tu-closable-card *ngIf="auth.networks.length > 1" klass="tw-mt-6" [defaultOpen]="true">
    <h5 card-header class="tw-m-0">
      {{ 'pages.customer_details.customer_networks_title' | translate }}
    </h5>
    ]
    <div card-content>
      <table class="tw-mb-4 tw-min-w-full">
        <caption class="tw-sr-only">
          {{ 'pages.customer_details.customer_networks' | translate }}
        </caption>

        <thead>
          <tr>
            <th class="tw-whitespace-nowrap tw-px-4 tw-py-3">
              <span>{{ 'pages.customer_details.network_name' | translate }}</span>
            </th>

            <th
              [hasAllPermission]="['MANAGE_CUSTOMER_NETWORK']"
              class="tw-whitespace-nowrap tw-px-4 tw-py-3 tw-text-center"
            >
              <span *ngIf="isCustomerParent">
                {{ 'pages.customer_details.actions' | translate }}
              </span>
            </th>
          </tr>
        </thead>

        <tbody class="tw-border-b tw-border-b-gray-300">
          <tr
            *ngIf="
              !auth.permissions.includes('MANAGE_CUSTOMER_NETWORK') &&
                !$$customer.network_ids?.length;
              else network_table
            "
          >
            <td colspan="2" class="tw-px-4 tw-py-3 tw-text-center">
              {{ 'pages.customer_details.no_network_message' | translate }}
            </td>
          </tr>

          <ng-template #network_table>
            <tr *ngFor="let network of $$networks" class="hover:tw-bg-gray-50">
              <td class="tw-px-4 tw-py-3">
                <span class="tw-whitespace-nowrap">
                  {{ network.name }}
                </span>
              </td>

              <td
                [hasAllPermission]="['MANAGE_CUSTOMER_NETWORK']"
                class="tw-w-12 tw-px-4 tw-py-3 tw-text-center"
              >
                <button
                  *ngIf="isCustomerParent"
                  type="button"
                  [title]="'pages.customer_details.dissociate_network' | translate"
                  (click)="$$openNetworkDissociationModal(network.id)"
                >
                  <span class="tw-sr-only">Supprimer</span>
                  <i class="fa fa-trash-o font-2xl"></i>
                </button>
              </td>
            </tr>

            <tr *ngIf="$$isAddingNetwork">
              <td class="tw-px-4 tw-py-3">
                <select
                  id="newNetwork"
                  [disabled]="$$newNetworkLoading"
                  name="newNetwork"
                  class="form-control tw-h-4 tw-w-96"
                  [(ngModel)]="$$newNetworkId"
                >
                  <option class="selector-title" disabled="disabled">
                    {{ 'pages.customer_details.network' | translate }}
                  </option>

                  <option
                    *ngFor="let network of auth.networks"
                    [ngValue]="network.id"
                    class="selector-title"
                  >
                    {{ network.name }}
                  </option>
                </select>
              </td>

              <td
                [hasAllPermission]="['MANAGE_CUSTOMER_NETWORK']"
                class="tw-w-12 tw-px-4 tw-py-3 tw-text-center"
              >
                <button
                  *ngIf="isCustomerParent"
                  type="button"
                  [title]="'pages.customer_details.associate_network' | translate"
                  [disabled]="$$isAddingNetworkLoading"
                  (click)="$$saveNetwork($$newNetworkId)"
                >
                  <span class="tw-sr-only">{{ 'otherslabels.btn_save' | translate }}</span>
                  <i class="fa fa-save tw-text-xl"></i>
                </button>
              </td>
            </tr>

            <tr
              *ngIf="isCustomerParent"
              [hasAllPermission]="['MANAGE_CUSTOMER_NETWORK']"
              class="tw-border-t tw-border-t-gray-300"
            >
              <td colspan="2" class="tw-text-center">
                <button
                  *ngIf="!$$isAddingNetwork; else cancelAddingNetwork"
                  type="button"
                  (click)="$$isAddingNetwork = true"
                  class="btn btn-sm btn-primary tw-m-4"
                >
                  <tu-hero-icon
                    [path]="heroicons.outline.plusCircle"
                    klass="tw-h-5 tw-inline-block tw-mx-auto"
                  ></tu-hero-icon>

                  <span>
                    {{ 'pages.customer_details.add_network' | translate }}
                  </span>
                </button>

                <ng-template #cancelAddingNetwork>
                  <button
                    type="button"
                    [disabled]="$$newNetworkLoading"
                    (click)="$$isAddingNetwork = false"
                    class="btn btn-sm btn-warning tw-m-4"
                  >
                    <span>{{ 'otherslabels.btn_cancel' | translate }}</span>
                  </button>
                </ng-template>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </tu-closable-card>

  <!-- DOCUMENTS -->
  <tu-closable-card klass="tw-mt-6">
    <div card-header class="tw-flex tw-w-full tw-items-center tw-justify-between">
      <h5 class="tw-m-0">{{ 'pages.customer_details.documents' | translate }}</h5>
      <button
        class="btn btn-secondary btn-sm tw-m-0"
        (click)="openDocumentUploadModal()"
      >
        {{ 'pages.document_upload_modal.title' | translate }}
      </button>
    </div>

    <div card-content>
      <tu-tabs *ngIf="!$$loadingDocuments; else loadingDocuments">
        <tu-tab [tabTitle]="'pages.customer_details.active_document' | translate">
          <div class="tw-mt-2">
            <tu-table
              id="documents"
              [config]="docTableConfig"
              [content]="$$documents"
              [checkboxes]="false"
              [pagination]="false"
            ></tu-table>
          </div>
        </tu-tab>

        <tu-tab [tabTitle]="'pages.customer_details.deleted_document' | translate">
          <div class="tw-mt-2">
            <tu-table
              id="deletedDocuments"
              class="tw-mt-2"
              [config]="deletedDocTableConfig"
              [content]="$$deletedDocuments"
              [checkboxes]="false"
              [pagination]="false"
            ></tu-table>
          </div>
        </tu-tab>
      </tu-tabs>

      <ng-template #loadingDocuments>
        <p>{{ 'otherslabels.loading' | translate }}</p>
      </ng-template>
    </div>
  </tu-closable-card>

  <!-- SUBMISSIONS -->
  <tu-closable-card klass="tw-mt-6">
    <h5 card-header class="tw-m-0">{{ 'pages.customer_details.submissions' | translate }}</h5>

    <div card-content>
      <tu-table
        *ngIf="!$$loadingSubmissions; else loadingSubmissions"
        id="submissions"
        [config]="submissionsTableConfig"
        [content]="$$submissions"
        [checkboxes]="false"
        [pagination]="false"
      ></tu-table>

      <ng-template #loadingSubmissions>
        <p>{{ 'otherslabels.loading' | translate }}</p>
      </ng-template>
    </div>
  </tu-closable-card>

  <!-- VOUCHERS -->
  <tu-closable-card klass="tw-mt-6">
    <h5 card-header class="tw-m-0">{{ 'pages.customer_details.vouchers' | translate }}</h5>

    <div card-content>
      <tu-table
        id="vouchers"
        *ngIf="!$$loadingVouchers; else loadingVouchers"
        [config]="voucherTableConfig"
        [content]="$$vouchers"
        [checkboxes]="false"
        [pagination]="false"
      ></tu-table>

      <ng-template #loadingVouchers>
        <p>{{ 'otherslabels.loading' | translate }}</p>
      </ng-template>
    </div>
  </tu-closable-card>

  <!-- ATTRIBUTES -->
  <tu-closable-card klass="tw-mt-6">
    <h5 card-header class="tw-m-0">{{ 'pages.customer_details.metadata' | translate }}</h5>

    <div card-content>
      <tu-table
        *ngIf="!$$loadingAllAttributes && !$$loadingAttributes; else loadingAllAttributes"
        id="attributes"
        [config]="attributesTableConfig"
        [content]="$$allAttributes"
        [checkboxes]="false"
        [pagination]="false"
        [headers]="true"
      ></tu-table>

      <ng-template #loadingAllAttributes>
        <p>
          {{ 'pages.customer_details.loading_attributes' | translate }}
        </p>
      </ng-template>

      <p>
        {{ 'pages.customer_details.hasauthasguestorders' | translate }} :

        <strong class="tag tag-pill tag-primary tw-p-2">
          {{
            $$customer.hasAuthAsGuestOrders
              ? ('otherslabels.yes' | translate)
              : ('otherslabels.no' | translate)
          }}
        </strong>
      </p>
    </div>
  </tu-closable-card>

  <!-- SESSIONS -->
  <tu-closable-card *ngIf="isSuperAdmin" klass="tw-mt-6">
    <h5 card-header class="tw-m-0">{{ 'pages.customer_details.lastconnections' | translate }}</h5>

    <div card-content>
      <tu-table
        id="sessions"
        [config]="sessionTableConfig"
        [content]="sessions"
        [checkboxes]="false"
      ></tu-table>
    </div>
  </tu-closable-card>

  <!-- COMMENTS -->
  <tu-comments-ng
    [comments]="$$comments"
    [commentTemplate]="commentTemplateNg"
    [postComment]="postComment"
    [editComment]="editComment"
    [deleteComment]="deleteComment"
    [requiresNetwork]="true"
    [networks]="$$networks"
    (selectedNetwork)="onChangeCommentNetwork($event)"
  >
  </tu-comments-ng>

  <ng-template
    #commentTemplateNg
    let-comment="comment"
    let-commentToEdit="commentToEdit"
    let-editedComment="editedComment"
    let-commentToDelete="commentToDelete"
    let-markdownToHTML="markdownToHTML"
  >
    <tu-customer-comment
      [comment]="comment"
      [commentToEdit]="commentToEdit"
      [editedComment]="editedComment"
      [commentToDelete]="commentToDelete"
      [markdownToHTML]="markdownToHTML"
    ></tu-customer-comment>
  </ng-template>

  <div class="loader" *ngIf="deleteTicketLoading">
    <div class="spinner"></div>
  </div>

  <simple-notifications [options]="notificationOptions"></simple-notifications>
</ng-container>

<ng-template #loadingUser>
  <div>
    <p>{{ 'otherslabels.loading' | translate }}</p>
  </div>
</ng-template>
