import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Network } from '../models/network';
import { Session } from '../models/session';
import { ModalService } from './modal.service';
import { FilterService } from './filter.service';
import { environment as env } from '@env/environment';
import { GenericResponse } from '../models/generic-response';

@Injectable()
export class AuthService {
  public redirectUrl: string;
  public tokenLoader: boolean = false;
  private _networks: Network[] = JSON.parse(localStorage.getItem('networks'));

  constructor(
    private router: Router,
    private http: HttpClient,
    private modal: ModalService,
    private filterService: FilterService
  ) { }

  set username(username: string) {
    if (username) localStorage.setItem('username', username);
    else localStorage.removeItem('username');
  }
  get username(): string {
    return localStorage.getItem('username');
  }
  get loggedIn(): boolean {
    return Boolean(this.username);
  }

  set token(token: string) {
    if (token) {
      localStorage.setItem('token', token);
      //Auth cookie
      let cookieContent = `AW_AUTH_SESSION=${token}; SameSite=None; Secure;`;
      const [extension, domain] = window.location.hostname.split('.').reverse();
      if (domain && extension) {
        const DOMAIN = `.${domain}.${extension}`;
        cookieContent += `Domain=${DOMAIN}; Path:/`;
      }
      document.cookie = cookieContent;
    } else {
      localStorage.removeItem('token');
      this.clearAuthCookie();
    }
  }
  get token(): string {
    return localStorage.getItem('token');
  }

  set user_id(userId: string) {
    if (userId) localStorage.setItem('user_id', userId);
    else localStorage.removeItem('user_id');
  }
  get user_id(): string {
    return localStorage.getItem('user_id');
  }

  set networks(networks: Network[]) {
    // HACK: reset cache on networks
    delete this._networks;
    this._networks = networks;

    if (networks?.length) {
      // We copy the networks into it's own array, because the `sort` function mutates the original array...
      localStorage.removeItem('networks');
      const networksForLocalStorage = [...networks].sort((a, b) => a.name.localeCompare(b.name));
      localStorage.setItem('networks', JSON.stringify(networksForLocalStorage));
    } else {
      localStorage.removeItem('networks');
    }
  }
  get networks(): Network[] {
    try {
      if (this._networks?.length) {
        return this._networks;
      }

      const networks = JSON.parse(localStorage.getItem('networks'));

      if (Array.isArray(networks)) {
        return networks;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }

  set currencies(currencies: string[]) {
    if (currencies) localStorage.setItem('currencies', JSON.stringify(currencies));
    else localStorage.removeItem('currencies');
  }
  get currencies(): string[] {
    return JSON.parse(localStorage.getItem('currencies'));
  }

  private normalizePermissions(permissions: string[] | Record<number, string>) {
    if (!permissions) return [];

    return Array.isArray(permissions) ? permissions : Object.values(permissions);
  }

  set permissions(permissions: Array<string>) {
    localStorage.setItem('permissions', JSON.stringify(this.normalizePermissions(permissions)));
  }
  get permissions(): Array<string> {
    let tmp = localStorage.getItem('permissions');
    return tmp ? this.normalizePermissions(JSON.parse(tmp)) : [];
  }

  set roleNames(permissions: Array<string>) {
    localStorage.setItem('roles', JSON.stringify(this.normalizePermissions(permissions)));
  }
  get roleNames(): Array<string> {
    let tmp = localStorage.getItem('roles');
    return tmp ? this.normalizePermissions(JSON.parse(tmp)) : [];
  }

  get isSuperAdmin(): boolean {
    return this.roleNames.some((role) => role === 'SUPER_ADMIN');
  }

  public hasPermission(permission: string) {
    return this.permissions.includes(permission);
  }

  public login(user: string, password: string): Observable<Promise<boolean>> {
    return this.http
      .post<GenericResponse>(env.config.feedRoot + `Authenticate/login`, { user, password })
      .pipe(
        map(async ({ response }) => {
          if (!response.success) return false;

          this.user_id = response.id;
          this.networks = response.networks;
          this.currencies = response.networks.reduce((currencies, network) => {
            if (currencies.includes(network.currency)) return currencies;

            return [...currencies, network.currency];
          }, []);
          this.permissions = response.permissions;
          this.username = user;
          this.token = response.token;
          const session = await this.session();
          this.roleNames = session.user?.roles?.map(({ name }) => name);
          return response.success;
        })
      );
  }

  public async logout() {
    try {
      await firstValueFrom(
        this.http.post<GenericResponse<boolean>>(env.config.feedRoot + `Authenticate/logout`, {})
      );
    } catch {
    } finally {
      this.clearStorage();
      this.filterService.network = null;
      this.networks = [];
    }
  }

  public session() {
    return firstValueFrom(
      this.http
        .get<GenericResponse<Session>>(env.config.feedRoot + `Authenticate/session`, {})
        .pipe(map(({ response }) => response))
    );
  }

  private clearAuthCookie() {
    document.cookie = document.cookie
      .split(';')
      .map((cookie) =>
        cookie.includes('AW_AUTH_SESSION')
          ? `AW_AUTH_SESSION=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
          : cookie
      )
      .join(';');
  }

  public clearStorage() {
    localStorage.removeItem('username');
    localStorage.removeItem('networks');
    localStorage.removeItem('currencies');
    localStorage.removeItem('user_id');
    localStorage.removeItem('permissions');
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    this.clearAuthCookie();
  }

  public checkLoggedIn(response: any) {
    if (response?.errorMessage) {
      this.clearStorage();
    }
  }

  public checkLogin(): boolean {
    // Store the attempted URL for redirecting
    this.redirectUrl = location.pathname;

    // Navigate to the login page with extras
    if (!this.loggedIn) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: location.pathname },
        queryParamsHandling: 'merge',
      });
    }

    return this.loggedIn;
  }

  public getNetwork(networkId: string): Network {
    return this.networks.find((network) => +network.id === +networkId);
  }

  public getUserNetworks() {
    return this.http
      .get<GenericResponse>(`${env.config.feedRoot}/Network/updateLocalNetworks`)
      .pipe(map(({ response }) => response?.networks));
  }

  public getNetworks() {
    const antiCache = Math.random().toString(36).slice(2, 5);

    return this.http
      .get<GenericResponse>(`${env.config.feedRoot}/Network/getNetworks`, {
        params: {
          withId: true,
          antiCache,
        },
      })
      .pipe(map(({ response }) => response?.networks));
  }

  public sessionTimeoutModal() {
    return this.modal.open('Votre session a expiré', 'Veuillez vous reconnecter', [
      { label: 'Se reconnecter', value: 'REDIRECT' },
    ]);
  }

  public async loginWithToken(token: string): Promise<void> {
    this.token = token;

    const [session, networks] = await Promise.all([
      this.session(),
      firstValueFrom(this.getNetworks()),
    ]);

    // HACK: Get locales & configuration of network
    const userNetworks = session.user.networks.reduce((networkList, network) => {
      const networkWithLocale = networks.find(({ id }) => network.id === id);

      if (networkWithLocale) {
        const localeList = networkWithLocale.languages.map(({ locale }) => locale);

        networkList.push({
          locales: localeList,
          ...networkWithLocale.configuration,
          ...network,
        });
      }

      return networkList;
    }, []);

    this.user_id = session.user.id.toString();
    this.networks = userNetworks;
    this.currencies = userNetworks.reduce((currencies, network) => {
      if (currencies.includes(network.currency)) return currencies;

      return [...currencies, network.currency];
    }, []);

    const permissions = session.user.roles.reduce((permissionList, role) => {
      const rolesPermissionLabelList = role.permissions.map(({ label }) => label);
      return [...new Set([...permissionList, ...rolesPermissionLabelList])];
    }, []);

    this.permissions = permissions;
    this.username = session.user.login;
    this.roleNames = session.user?.roles?.map(({ name }) => name);
  }
}
