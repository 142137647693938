import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { Blueprint } from '@app/modules/shop/models/blueprint/blueprint';
import { environment as env } from '@env/environment';
import { firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface $$Network {
  id: string;
  name: string;
  categories: $$Category[];
}

export interface $$Category {
  id: string;
  name: string;
  items: $$Item[];
}

export interface $$Item {
  id: string;
  name: string;
  is_od: boolean;
  products: $$Product[];
}

export interface $$Product {
  id: string;
  name: string;
  photoMandatory: number;
  dematerialized?: null | string;
}

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  public getProducts(): Observable<$$Network[]> {
    return this.http.get<GenericResponse<{ networks: $$Network[] }>>(env.config.feedRoot + 'Product/getProducts.json').pipe(
      map(({ response }) => {
        return response.networks;
      })
    );
  }

  public $$getProducts(): Promise<$$Item[]> {
    const http$ = this.http
      .get<GenericResponse<{ networks: $$Network[] }>>(env.config.feedRoot + 'Product/getProducts')
      .pipe(
        map(({ response }) => {
          return response.networks
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((items, network) => {
              for (const category of network.categories) {
                items.push(...category.items);
              }

              return items;
            }, []);
        })
      );

    return firstValueFrom(http$);
  }

  public getNetworkItems(network_id: string) {
    return this.http
      .get<GenericResponse>(env.config.feedRoot + `Product/getNetworkItems.json`, {
        params: { network_id },
      })
      .pipe(
        map(({ response }) => {
          return response;
        })
      );
  }

  public getNetworkCategories(network_id: string) {
    return this.http
      .get<GenericResponse>(env.config.feedRoot + `Product/getNetworkCategories.json`, {
        params: { network_id },
      })
      .pipe(
        map(({ response }) => {
          return response;
        })
      );
  }

  public getNetworksBlueprints(params: Partial<{ network: string[] }> = {}) {
    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(`${env.config.feedRoot}Wallet/blueprints`, {
          params: {
            network: `[${params.network}]`,
            deleted: 0,
          },
        })
      ),

      map(({ response }) => {
        return response ? (response as Blueprint[]) : null;
      })
    );
  }

  public getDatePreview(params: {
    pivot_type: string;
    pivot_value: string;
    start_proposals: number;
    timezone?: string;
  }) {
    return this.http.get(`${env.config.feedRoot}product/getProductStartDates`, {
      params,
    });
  }
}
