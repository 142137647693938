<tu-loader *ngIf="isLoading; else content"></tu-loader>

<ng-template #content>
  <h5 mat-dialog-title class="mb-1">
    {{
      data.transfert
        ? ('pages.generate_ticket_modal.modal_title_redistribute' | translate)
        : ('pages.generate_ticket_modal.modal_title' | translate)
    }}
  </h5>

  <mat-stepper linear #stepper>
    <mat-step [stepControl]="productFormGroup" [editable]="true">
      <form [formGroup]="productFormGroup">
        <ng-template matStepLabel>
          {{ 'pages.generate_ticket_modal.product_choice' | translate }}
        </ng-template>

        <div class="form-group row tw-mt-2 tw-flex tw-items-center">
          <label class="col-md-2 form-control-label" for="network">
            {{ 'pages.generate_ticket_modal.network' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <mat-form-field
              *ngIf="!data.transfert; else networkLabel"
              appearance="outline"
              floatLabel="never"
              class="tw-w-full"
            >
              <mat-label>{{ 'pages.generate_ticket_modal.select_network' | translate }}</mat-label>
              <mat-select formControlName="networkId">
                <mat-option
                  *ngFor="let customerNetwork of customerNetworks"
                  [value]="customerNetwork.network.id"
                >
                  {{ customerNetwork.network.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="productFormGroup.controls.networkId.hasError('required')"
                class="tw-mt-4"
              >
                {{ 'pages.generate_ticket_modal.field_required' | translate }}
              </mat-error>
            </mat-form-field>
            <ng-template #networkLabel>
              <span>{{ data.transfert?.network_name }}</span>
            </ng-template>
          </div>
        </div>

        <ng-container *ngIf="isTicketV1">
          <div class="form-group row tw-mt-2 tw-flex tw-items-center">
            <label class="col-md-2 form-control-label" for="category">
              {{ 'pages.generate_ticket_modal.category' | translate }}
            </label>

            <div class="col-md-5 d-flex">
              <mat-form-field
                *ngIf="!data?.transfert?.ticket?.category_id; else categoryLabel"
                appearance="outline"
                floatLabel="never"
                class="tw-w-full"
              >
                <mat-select
                  [formControl]="productFormGroup.controls.categoryId"
                  name="category"
                  id="category"
                  placeholder="{{ 'pages.generate_ticket_modal.select_category' | translate }}"
                >
                  <mat-option *ngFor="let category of categories" [value]="category.id">
                    {{ category.name }}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="!productFormGroup.value.networkId" class="tw-mt-4">
                  {{ 'pages.generate_ticket_modal.select_network_first_message' | translate }}
                </mat-error>
              </mat-form-field>

              <ng-template #categoryLabel>
                <span>{{ category?.name || '' }}</span>
              </ng-template>
            </div>
          </div>

          <div class="form-group row tw-mt-2 tw-flex tw-items-center">
            <label class="col-md-2 form-control-label" for="product">
              {{ 'pages.generate_ticket_modal.product' | translate }}
            </label>

            <div class="col-md-5 d-flex">
              <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
                <mat-select
                  [formControl]="productFormGroup.controls.productId"
                  name="product"
                  id="product"
                  placeholder="{{ 'pages.generate_ticket_modal.select_product' | translate }}"
                >
                  <mat-option *ngFor="let product of products" [value]="product.id">
                    {{ product.name }}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="!productFormGroup.value.categoryId" class="tw-mt-4">
                  {{ 'pages.generate_ticket_modal.select_category_first_message' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group row tw-mt-2 tw-flex tw-items-center">
            <label class="col-md-2 form-control-label" for="productType">
              {{ 'pages.generate_ticket_modal.product_type' | translate }}
            </label>

            <div class="col-md-5 d-flex">
              <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
                <mat-select
                  [formControl]="productFormGroup.controls.productTypeId"
                  name="productType"
                  id="productType"
                  placeholder="{{ 'pages.generate_ticket_modal.select_product_type' | translate }}"
                >
                  <mat-option *ngFor="let productType of productTypes" [value]="productType.id">
                    {{ productType.name }}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="!productFormGroup.value.productId" class="tw-mt-4">
                  {{ 'pages.generate_ticket_modal.select_product_first_message' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isTicketV2">
          <div class="form-group row tw-mt-2 tw-flex tw-items-center">
            <label class="col-md-2 form-control-label" for="category">
              {{ 'pages.customer_details.label_product_type' | translate }}
            </label>

            <div class="col-md-5 d-flex">
              <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
                <mat-select
                  [formControl]="productFormGroup.controls.blueprint"
                  name="blueprint"
                  id="blueprint"
                  placeholder="Sélectionner un type de titre"
                >
                  <mat-option *ngFor="let blueprint of blueprints" [value]="blueprint?.id">
                    {{ blueprint?.name }} --
                    <span class="tw-text-xs tw-italic">{{ blueprint?.id }}</span>
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="!productFormGroup.value.blueprint" class="tw-mt-4">
                  {{ 'pages.customer_details.select_product_type' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </ng-container>

        <div class="form-group row tw-mt-2 tw-flex tw-items-center">
          <label class="col-md-2 form-control-label" for="startValidityDate">
            {{ 'pages.customer_details.ticket_begin_date' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <div class="input-group tw-w-1/3">
              <span class="input-group-addon">J</span>
              <input
                formControlName="startValidityDate"
                type="date"
                id="startValidityDate"
                name="startValidityDate"
                class="form-control required"
                placeholder=""
              />
            </div>

            <div class="input-group tw-ml-5 tw-w-1/4">
              <span class="input-group-addon">H</span>
              <input
                formControlName="startValidityTime"
                type="time"
                id="startValidityTime"
                name="startValidityTime"
                class="form-control required"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div class="form-group row tw-mt-2 tw-flex tw-items-center">
          <label class="col-md-2 form-control-label" for="endValidityDate">
            {{ 'pages.customer_details.ticket_end_date' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <div class="input-group tw-w-1/3">
              <span class="input-group-addon">J</span>
              <input
                formControlName="endValidityDate"
                type="date"
                id="endValidityDate"
                class="form-control required"
                name="endValidityDate"
                placeholder=""
              />
            </div>

            <div class="input-group tw-ml-5 tw-w-1/4">
              <span class="input-group-addon">H</span>
              <input
                formControlName="endValidityTime"
                id="endValidityTime"
                type="time"
                name="endValidityTime"
                class="form-control required"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div class="form-group row tw-mt-2 tw-flex tw-items-center">
          <label class="col-md-2 form-control-label" for="generationReason">
            {{ 'pages.customer_details.generationreason' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
              <mat-select
                [formControl]="productFormGroup.controls.generationReason"
                name="generationReason"
                id="generationReason"
                [placeholder]="'pages.customer_details.generationreason_select' | translate"
              >
                <mat-option
                  *ngFor="let productPurpose of productPurposes"
                  [value]="productPurpose[0]"
                >
                  {{ productPurpose[1] | translate }}
                </mat-option>
              </mat-select>

              <mat-error *ngIf="!productFormGroup.value.generationReason" class="tw-mt-4">
                {{ 'pages.generate_ticket_modal.field_required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group row tw-mt-2 tw-flex tw-items-center">
          <label class="col-md-2 form-control-label" for="quantity">
            {{ 'pages.generate_ticket_modal.quantity' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
              <input
                name="quantity"
                id="quantity"
                type="number"
                placeholder="{{ 'pages.generate_ticket_modal.quantity' | translate }}"
                min="1"
                matInput
                [formControl]="productFormGroup.controls.quantity"
              />

              <mat-error
                *ngIf="productFormGroup.controls.quantity.hasError('required')"
                class="tw-mt-4"
              >
                {{ 'pages.generate_ticket_modal.field_required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group row tw-mt-2 tw-flex tw-items-center">
          <label class="col-md-2 form-control-label" for="generationComment">
            {{ 'pages.customer_details.comment' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
              <textarea
                id="generationComment"
                name="generationComment"
                formControlName="generationComment"
                matInput
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group row tw-flex tw-justify-end tw-px-4">
          <button type="button" (click)="closeModal()" mat-flat-button class="tw-mr-2">
            {{ 'pages.generate_ticket_modal.cancel' | translate }}
          </button>

          <button
            type="button"
            (click)="getProductOptions(stepper)"
            mat-flat-button
            class="btn-primary"
          >
            {{ 'pages.generate_ticket_modal.confirm_product' | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="productInfoFormGroup" [editable]="true" *ngIf="needMoreInfos">
      <!-- Not ideal -->
      <form [formGroup]="productInfoFormGroup" class="tw-max-h-96 tw-overflow-y-auto">
        <ng-template matStepLabel>
          {{ 'pages.generate_ticket_modal.product_infos' | translate }}
        </ng-template>

        <div
          *ngIf="hasSupportChoice"
          class="form-group row tw-mt-2 tw-flex tw-w-full tw-items-center"
        >
          <label class="col-md-2 form-control-label" for="support">
            {{ 'pages.generate_ticket_modal.support' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
              <mat-select
                [formControl]="productInfoFormGroup.controls.dematerialized"
                name="support"
                id="support"
                placeholder="{{ 'pages.generate_ticket_modal.select_support' | translate }}"
              >
                <mat-option value="1">
                  {{ 'pages.generate_ticket_modal.dematerialized' | translate }}
                </mat-option>
                <mat-option value="0">
                  {{ 'pages.generate_ticket_modal.physical' | translate }}
                </mat-option>
              </mat-select>

              <mat-error
                *ngIf="productInfoFormGroup.controls.dematerialized.hasError('required')"
                class="tw-mt-4"
              >
                {{ 'pages.generate_ticket_modal.field_required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="isProductOD" class="form-group row tw-mt-2 tw-flex tw-w-full tw-items-center">
          <label class="col-md-2 form-control-label" for="originStop">
            {{ 'pages.generate_ticket_modal.origin_stop' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
              <input
                type="search"
                [formControl]="originStopControl"
                [matAutocomplete]="originStopAuto"
                [placeholder]="'pages.generate_ticket_modal.select_origin_stop' | translate"
                matInput
                name="originStop"
                [required]="isProductOD"
                [attr.disabled]="loadingOriginStops ? true : null"
                class="required-field"
              />

              <mat-autocomplete autoActiveFirstOption #originStopAuto="matAutocomplete">
                <mat-option
                  *ngFor="let stop of filteredOriginStops"
                  [ngClass]="{
                    selected:
                      originStopControl.value !== null &&
                      originStopControl.value.toLowerCase() === stop.stop_name.toLowerCase()
                  }"
                  [value]="stop.stop_name"
                >
                  {{ stop.stop_name }}
                </mat-option>
              </mat-autocomplete>

              <mat-error
                *ngIf="productInfoFormGroup.controls.origin.hasError('required')"
                class="tw-mt-4"
              >
                {{ 'pages.generate_ticket_modal.field_required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <tu-loader *ngIf="loadingOriginStops" klass="tw-w-4"></tu-loader>
        </div>

        <div *ngIf="isProductOD" class="form-group row tw-mt-2 tw-flex tw-w-full tw-items-center">
          <label class="col-md-2 form-control-label" for="destinationStop">
            {{ 'pages.generate_ticket_modal.destination_stop' | translate }}
          </label>

          <div class="col-md-5 d-flex">
            <mat-form-field appearance="outline" floatLabel="never" class="tw-w-full">
              <input
                type="search"
                [formControl]="destinationStopControl"
                [matAutocomplete]="destinationStopAuto"
                [placeholder]="'pages.generate_ticket_modal.select_destination_stop' | translate"
                matInput
                name="destinationStop"
                [required]="isProductOD"
                [attr.disabled]="(!originStopControl.value || loadingDestinationStops) ? true : null"
                class="required-field"
              />

              <mat-autocomplete autoActiveFirstOption #destinationStopAuto="matAutocomplete">
                <mat-option
                  *ngFor="let stop of filteredDestinationStops"
                  [ngClass]="{
                    selected:
                      destinationStopControl.value !== null &&
                      destinationStopControl.value.toLowerCase() === stop.stop_name.toLowerCase()
                  }"
                  [value]="stop.stop_name"
                >
                  {{ stop.stop_name }}
                </mat-option>
              </mat-autocomplete>

              <mat-error
                *ngIf="productInfoFormGroup.controls.destination.hasError('required')"
                class="tw-mt-4"
              >
                {{ 'pages.generate_ticket_modal.field_required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <tu-loader *ngIf="loadingDestinationStops" klass="tw-w-4"></tu-loader>
        </div>

        <div *ngIf="isProductOD" class="form-group row tw-flex tw-w-full tw-flex-col">
          <div class="col-md-5 offset-md-2" *ngIf="isTripFound">
            <p class="text-success">{{ 'pages.generate_ticket_modal.trip_found' | translate }}</p>
          </div>

          <div class="col-md-5 offset-md-2" *ngIf="tripErrorMessage">
            <p class="text-danger">
              {{ tripErrorMessage }}
            </p>
          </div>

          <div class="col-md-5 offset-md-2">
            <button type="button" (click)="searchTrip()" mat-button class="btn-primary">
              {{ 'pages.generate_ticket_modal.search_trip' | translate }}
            </button>
          </div>
        </div>

        <div *ngIf="isPhotoMandatory" class="form-group row tw-w-full">
          <label class="col-md-2 form-control-label" for="originStop">
            {{ 'pages.generate_ticket_modal.photo' | translate }}
          </label>

          <div class="col-md-5 tw-flex tw-flex-col">
            <p class="text-danger tw-m-0" *ngIf="avatarErrorMessage">
              {{ avatarErrorMessage }}
            </p>

            <tu-avatar-update-modal
              [withTitle]="false"
              [customerData]="data.customer"
              (updateField)="onUpdateAvatar($event)"
            ></tu-avatar-update-modal>
          </div>
        </div>

        <div class="form-group row tw-m-0 tw-flex tw-w-full tw-justify-end">
          <button type="button" (click)="closeModal()" mat-flat-button class="tw-mr-2">
            {{ 'pages.generate_ticket_modal.cancel' | translate }}
          </button>

          <button type="button" mat-button (click)="goForward(stepper)" class="btn-primary">
            {{ 'pages.generate_ticket_modal.confirm_info' | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        {{ 'pages.generate_ticket_modal.ticket_generation' | translate }}
      </ng-template>
      <div class="tw-my-2">
        <p>{{ 'pages.generate_ticket_modal.ticket_summary' | translate }}</p>

        <p class="tw-m-0">
          {{ 'pages.generate_ticket_modal.network' | translate }} :
          <strong>{{ network?.name }}</strong>
        </p>

        <p class="tw-m-0" *ngIf="isTicketV1">
          {{ 'pages.generate_ticket_modal.product' | translate }} :
          <strong>{{ product?.name }}</strong>
        </p>

        <p class="tw-m-0" *ngIf="isTicketV2">
          {{ 'pages.generate_ticket_modal.product_type' | translate }} :
          <strong>
            {{ blueprint?.name }}
          </strong>
          <span class="tw-text-xs tw-italic">{{ blueprint?.id }}</span>
        </p>

        <p class="tw-m-0">
          {{ 'pages.customer_details.generationreason' | translate }} :
          <strong>
            {{ generationReasonLabels[productFormGroup.value.generationReason] | translate }}
          </strong>
        </p>

        <p class="tw-m-0 tw-mb-2">
          {{ 'pages.generate_ticket_modal.quantity' | translate }} :
          <strong>{{ productFormGroup.get('quantity').value }}</strong>
        </p>

        <p class="tw-m-0" *ngIf="productFormGroup.value?.startValidityDate">
          {{ 'pages.customer_details.ticket_begin_date' | translate }} :
          <strong>
            {{ productFormGroup.value.startValidityDate | date }}
            {{ productFormGroup.value.startValidityTime }}
          </strong>
        </p>

        <p *ngIf="productFormGroup.value?.endValidityDate">
          {{ 'pages.customer_details.ticket_end_date' | translate }} : :
          <strong>
            {{ productFormGroup.value.endValidityDate | date }}
            {{ productFormGroup.value.endValidityTime }}
          </strong>
        </p>

        <p class="tw-m-0" *ngIf="isTicketV1">
          {{ 'pages.generate_ticket_modal.support' | translate }} :
          <strong>
            {{
              productInfoFormGroup.value.dematerialized === '1'
                ? ('pages.generate_ticket_modal.dematerialized' | translate)
                : ('pages.generate_ticket_modal.physical' | translate)
            }}
          </strong>
        </p>

        <p *ngIf="isProductOD" class="tw-m-0">
          {{ 'pages.generate_ticket_modal.origin_destination' | translate }} :
          <strong>
            {{ productInfoFormGroup.value.origin?.stop_name }}-{{
              productInfoFormGroup.value.destination?.stop_name
            }}
            ({{ productInfoFormGroup.value.trip?.fareId }})
          </strong>
        </p>

        <p *ngIf="isPhotoMandatory" class="tw-m-0">
          {{ 'pages.generate_ticket_modal.photo' | translate }} :
          <strong>{{ productInfoFormGroup.value.avatar }}</strong>
        </p>
      </div>

      <aside
        *ngIf="desactivateTicketOption !== null"
        class="alert tw-mb-4 tw-mt-4 tw-rounded-lg"
        [ngClass]="{
          'alert-danger': desactivateTicketOption === 'WARN' || desactivateTicketOption === 'KO',
          'alert-success': desactivateTicketOption === 'OK',
          'alert-info': desactivateTicketOption === 'WAIT'
        }"
        [ngSwitch]="desactivateTicketOption"
      >
        <p>
          <strong>
            {{ 'pages.customer_details.unactive_original_ticket' | translate }}
          </strong>
        </p>
        <p>
          <ng-container *ngSwitchCase="'WARN'">
            {{ 'pages.customer_details.deactivate_ticket_for_security' | translate }}
            <br />
            {{ 'pages.customer_details.access_only_new_ticket' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="'KO'">
            {{ 'pages.customer_details.error_unactive_ticket' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="'OK'">
            {{ 'pages.customer_details.old_ticket_inactive' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="'WAIT'">
            {{ 'pages.customer_details.processing' | translate }}
          </ng-container>
        </p>
        <button *ngSwitchCase="'WARN'" class="btn btn-danger" (click)="desactivateTicket()">
          {{ 'pages.customer_details.desactivate' | translate }}
        </button>
      </aside>

      <div class="tw-flex tw-justify-end tw-px-4">
        <button
          type="button"
          (click)="closeModal()"
          mat-flat-button
          class="tw-mr-2"
          [disabled]="ticketGenerationInProcess"
        >
          {{ 'pages.generate_ticket_modal.cancel' | translate }}
        </button>

        <button
          type="button"
          (click)="generateTicket()"
          mat-button
          class="btn-primary"
          [disabled]="ticketGenerationInProcess"
        >
          {{ 'pages.generate_ticket_modal.generate_ticket' | translate }}
        </button>
      </div>
    </mat-step>
  </mat-stepper>

  <simple-notifications [options]="notificationOptions"></simple-notifications>
</ng-template>

