// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  config: {
    // feedRoot: 'https://admin.ticket-universel.airweb.fr/feed/',
    feedRoot: 'https://legacy-bo-ra6rh2jj5a-ew.a.run.app/feed/',
    tapRoot: 'https://staging-api-kcvqfuvlla-ew.a.run.app/',
    abtRoot: 'https://abt-ra6rh2jj5a-ew.a.run.app',
    exportRoot: 'https://latest---exports-ra6rh2jj5a-ew.a.run.app/',
    mapApiKey: 'AIzaSyBBqcl8KrnY5PJetpLkmNT6SwxVdZwmDtQ',
    defaultCoords: '46.71109,1.7191036',
    loopedInApiBaseUrl: 'https://europe-west9-ticket-cms.cloudfunctions.net/develop-looped-in',
    loopedInWorkspaceId: '626a4cf7545a45002a3c5f02',
    clarityCode: 'fake-code',
    statsRoot: 'https://stats.staging.airweb.fr',
    assetsRoot: 'https://assets.ticket.airweb.dev/',
    configURL: 'https://storage.googleapis.com/airweb-ticket-public-europe/back-office/config.json',

    adminlabsURL: 'https://europe-west9-ticket-cms.cloudfunctions.net/develop-admin-labs',
    adminlabsBackOfficeComponentIds: [
      '960e8d2b-1a1f-11ed-ac0f-000c29d658bc',
      '46060d83-1a1f-11ed-ac0f-000c29d658bc',
      '8c6ae7a8-1a1f-11ed-ac0f-000c29d658bc',
      'c9a3059d-1c71-11eb-ac0f-000c29d658bc',
      '58f70937-edf3-11ea-ac0f-000c29d658bc',
      '555bc964-2b1f-11eb-ac0f-000c29d658bc',
      '5c638f3c-edf3-11ea-ac0f-000c29d658bc',
      '9356ed0e-2b20-11eb-ac0f-000c29d658bc',
      'ad9f396b-22ab-11eb-ac0f-000c29d658bc',
      '376398d1-22b0-11eb-ac0f-000c29d658bc',
      '543f8e0a-edf3-11ea-ac0f-000c29d658bc',
      '3d45399d-1a1f-11ed-ac0f-000c29d658bc',
      '79eb74b3-1a1f-11ed-ac0f-000c29d658bc',
      'c253d2aa-1c71-11eb-ac0f-000c29d658bc',
      '482d9348-edf3-11ea-ac0f-000c29d658bc',
      '844eebf6-22ab-11eb-ac0f-000c29d658bc',
      '37314b6b-6f36-11ee-ac0f-000c29d658bc',
      '9105d282-1c4c-11eb-ac0f-000c29d658bc',
      '4ddd7a70-edf3-11ea-ac0f-000c29d658bc',
      '593e5428-ff10-11ea-ac0f-000c29d658bc',
      '3c2b825f-edf3-11ea-ac0f-000c29d658bc',
      'b75e5fc8-1c71-11eb-ac0f-000c29d658bc',
      '64312ffe-2b20-11eb-ac0f-000c29d658bc',
      '82d49049-1a1f-11ed-ac0f-000c29d658bc',
      '538eba7c-ff10-11ea-ac0f-000c29d658bc',
      '8c44ded8-22ab-11eb-ac0f-000c29d658bc',
    ],
    airwebStatusUrl: 'https://status.airweb.fr/',
    newAuthenticationUrl: 'https://staging-auth-module-6lp4usbfna-ew.a.run.app/auth',
    authFactorTutoPath: '/administration/documentation/6e4fd5ae-3dd5-11ef-bcc2-2abc636a44d3.pdf',
    maintenanceUrlList: ['https://test-beacon-api-xct63msgcq-ew.a.run.app/api/alerts'],

    matrixDocumentation:
      'https://www.notion.so/airweb/Template-des-matrices-OD-130ae269a45d807da4ece1bb39d58280',
  },
};
